import React from "react";
import { GitHub } from "react-feather";
import logo from "./logo.png";

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#0A1F44", // dark navy color, assumed from the screenshot
    padding: "0 1rem",
    height: "3.5rem",
    width: "100%",
    position: "fixed",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)", // adjusted for a darker shadow
    zIndex: 1,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: "white", // assuming white text for the logo
    textShadow: "0 0 10px rgba(255, 255, 255, 0.1)", // adjusted for a light text shadow
  },
  navLinks: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  link: {
    display: "flex",
    alignItems: "center",
    color: "white", // assuming white text for the navigation links
    textDecoration: "none",
    marginLeft: "1rem",
    textShadow: "0 0 10px rgba(255, 255, 255, 0.1)", // adjusted for a light text shadow
  },
  githubIcon: {
    marginRight: "0.5rem",
    color: "#E83A30", // assuming a red color similar to the 'Try for free' button in the screenshot
  },
};

export const Navbar = () => {
  return (
    <div className="navbar" style={styles.navbar}>
      <div className="logo" style={styles.logo}>
        <img src={logo} alt="Logo" height="30" style={{ marginRight: "0.5rem" }} />
        Hub22 Hosted Grok
      </div>
      <div className="nav-links" style={styles.navLinks}>
        <a href="https://github.com/woahai321/grok-debugger" target="_blank" style={styles.link}>
          <GitHub style={styles.githubIcon} />
          GitHub
        </a>
      </div>
    </div>
  );
};
